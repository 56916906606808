.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  /* We need to assaign this CSS Custom property to the body instead of :root, because of CSS Specificity and codepen stylesheet placement before loaded CKE5 content. */
  --ck-z-default: 100;
  --ck-z-modal: calc(var(--ck-z-default) + 999);
}
.ck.ck-balloon-panel.ck-balloon-panel_visible {
  z-index: 1301;
}

ckeditor {
  -webkit-user-select: text;
  user-select: text;
}
